import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import ReactQuill from 'react-quill';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import { Button, Alert, Form } from 'react-bootstrap';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import { addTokenHeader, logout } from '../utils';
import "react-datepicker/dist/react-datepicker.css";
registerLocale('zh-CN', zh)

const AddResponse = (props) => {
    const { topicid, closeDisplayResponseWindow } = props;
    const [addedDate, setAddedDate] = useState(new Date());
    const [responsecontent, setResponsecontent] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();

    const handleOnResponsecontentUpdate = (e) => {
        setResponsecontent(e);
    }

    const handleOnSubmit = (e) => {
        const payload = {
            responsecontent,
            addedDate
        };

        axios.post(`/api/response/${topicid}/submit`, payload, addTokenHeader())
        .then(res => {
            window.location.reload();
        }).catch(error => {
            if (error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
            }
            setError(error.response.data.msg);
        });
    };

    return (
        <div style={{marginBottom: 40}}>
            {
                error &&
                <Alert variant="danger">
                    {error}
                </Alert>
            }
            <ReactQuill 
                style={{marginBottom: 20}}
                value={responsecontent}
                modules={{toolbar: [
                    [{ header: [1, 2, 3, false] }],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    ['link', 'image'],
                    ['clean'],
                    ]}}
                onChange={handleOnResponsecontentUpdate} 
            />
            <div style={{textAlign: "center", marginTop: 15}}>
                {/* <Form.Label>话题回复时间 </Form.Label><br />
                    <DatePicker selected={addedDate} locale='zh-CN' onChange={(date) => setAddedDate(date)} /><br /> */}
                <Button variant="primary" style={{marginTop: 10}} onClick={handleOnSubmit}>
                    回复
                </Button>{' '}
                <Button variant="danger" style={{marginTop: 10}} onClick={closeDisplayResponseWindow}>
                    取消
                </Button>{' '}
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(AddResponse);