import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DOMPurify from 'dompurify';
import { Row, Col, Card, Badge, Image } from 'react-bootstrap';
import SiteNav from '../components/SiteNav';
import { convertDatesToChinese } from '../utils';
import faceIcon from '../images/face_icon.jpeg';
import commentIcon from '../images/comment-icon.png';
import Footer from '../components/Footer';

const Home = () => {
    const [topics, setTopics] = useState();
    const [loading, setLoading] = useState(true);
    const history = useHistory();

    useEffect(() => {
        getTopics();
    }, [])

    const getTopics = () => {
        axios.get('/api/topics')
            .then(res => {
                const tempTopics = res.data;
                for (var i=0; i<tempTopics.length; i++) {
                    let latestDate = new Date(tempTopics[i].topic.added_date);
                    for (var j=0; j<tempTopics[i].responses.length; j++) {
                        const responseDate = new Date(tempTopics[i].responses[j].added_date);
                        if (responseDate>latestDate){
                            latestDate = responseDate;
                        }
                    }
                    tempTopics[i]['latestDate'] = latestDate;
                }
                const sortedtempTopics = tempTopics.sort((a, b) => b.latestDate - a.latestDate);
                setTopics(sortedtempTopics);
                setLoading(false);
            })
            .catch(error => {
                window.alert(error.response.data.msg);
            })
    }

    return (
        <div>
            <SiteNav />
            <div style={{paddingTop: 40, paddingBottom: 40}}>
                <h1 className="text-center">
                    目前的话题
                </h1>
                <div className="container" style={{paddingTop: 40, paddingBottom: 40}}>
                    <Row>
                        {   
                            topics &&
                            topics.map(topic => {
                                return (
                                    <Col key={topic.topic.topicname} style={{marginBottom: 10}} lg={6} md={12} sm={12} xs={12}>
                                        <Card style={{ width: '100%', cursor: 'pointer' }} onClick={() => history.push(`/topic/${topic.topic._id}`)}>
                                            <Card.Body>
                                                <div style={{height: 300}}>
                                                    <Card.Title><strong>{topic.topic.topicname}</strong></Card.Title>
                                                    <div style={{maxHeight: 230, overflowY: 'auto', marginBottom: 10}}>
                                                        <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(topic.topic.topiccontent)}} className="html-outer" />
                                                        <hr />
                                                        {
                                                        topic.responses.length > 0 &&
                                                            <div>
                                                                <p>回复者：<span className="mb-2 text-muted">{topic.responses[0].username}</span></p>
                                                                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(topic.responses[0].responsecontent)}} className="html-outer" />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                                {
                                                    topic.topic.keywords.map((keyword, index) => {
                                                        return (
                                                            <span key={index}>
                                                                <Badge variant="primary" style={{padding: 10, marginBottom: 5}}>
                                                                    {keyword}
                                                                </Badge>{' '}
                                                            </span>
                                                        )
                                                    })
                                                }
                                                <Row>
                                                    <Col lg={4} md={4} xs={12}><Card.Subtitle style={{marginTop: 10}} className="mb-2 text-muted"><Image src={faceIcon} style={{width: 20, marginRight: 10}} />{topic.topic.username}</Card.Subtitle></Col>
                                                    <Col lg={4} md={4} xs={12}><Card.Subtitle style={{marginTop: 10}} className="mb-2 text-muted"><Image src={commentIcon} style={{width: 20, marginRight: 10}} />{topic.responses.length} 条评论</Card.Subtitle></Col>
                                                    <Col lg={4} md={4} xs={12}><Card.Subtitle style={{marginTop: 10}} className="mb-2 text-muted">{convertDatesToChinese(topic.topic.added_date)}</Card.Subtitle></Col>
                                                </Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Home;