import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Spinner, Card, Form, Button } from 'react-bootstrap'
import { Redirect, useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import NotFound from '../pages/NotFound';
import { addTokenHeader, logout } from '../utils';
import Footer from '../components/Footer';

const EditUser = (props) => {
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [notFound, setNotfound] = useState(false);
    let history = useHistory()

    useEffect(() => {
        const userID = props.match.params.id;
        axios.get(
            `/api/user/${userID}/edit`, addTokenHeader())
            .then(res => {
                setUser(res.data.user)
                setLoading(false)
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                    history.push('/login')
                }
                else {
                    window.alert(error.response.data.msg);
                    history.goBack();
                }
            })
    }, [history, props])

    const handleOnChange = e => {
        const { name, value } = e.target;
        setUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    
    const handleUpdateUser = () => {
        const userID = props.match.params.id;
        const payload = user;

        axios.post(`/api/user/${userID}/edit/submit`, payload, addTokenHeader())
        .then(res => {
            history.push("/users")
        }).catch(error => {
            if (error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
                history.push('/login')
            }
            else {
                window.alert(error.response.data.msg)
            }
        });
    }

    const handleOnSubmit = () => {
        if (window.confirm('你确定是否想更新本用户?')) handleUpdateUser();
    }

    return notFound ? <NotFound /> : (
        <div>
            {
                !props.isLoggedIn && <Redirect to="/login" />
            }
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    更新用户
                </h1>
                {
                    loading ?
                    <div style={{position: "relative"}}>
                        <Spinner style={{marginLeft: "50%", marginRight: "50%"}} animation="grow"/>
                    </div> :
                    <div className="container" style={{marginTop: 30, maxWidth: 600}}>
                        <Card>
                            <Card.Body>
                                {
                                    user &&
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>用户名</Form.Label>
                                            <Form.Control type="text" name="username" readOnly defaultValue={user.username} />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>用户邮箱</Form.Label>
                                            <Form.Control type="email" name="email" value={user.email} onChange={handleOnChange} />
                                        </Form.Group>
                                        <Form.Group controlId="formRole">
                                            <Form.Check inline name="role" label="超级用户" value="SUPER" onChange={handleOnChange} checked={user.role === "SUPER"} type="radio" />
                                            <Form.Check inline name="role" label="普通用户" value="USER" onChange={handleOnChange} checked={user.role === "USER"} type="radio" />
                                        </Form.Group>
                                    </Form>
                                }
                            </Card.Body>
                            <div style={{textAlign: 'center', paddingBottom: 30}}>
                                <Button variant="primary" onClick={handleOnSubmit}>更新内容</Button>{' '}
                            </div>
                        </Card>
                    </div>
                }
            </div>
            <Footer />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(EditUser);