import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Col, Image, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import DOMPurify from 'dompurify';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import { addTokenHeader, convertDatesToChinese, logout } from '../utils';
import faceIcon from '../images/face_icon.jpeg'
import deleteIcon from '../images/delete-icon.png'

const GetResponses = (props) => {
    const { topicid } = props;
    const [loading, setLoading] = useState(true)
    const [responsecontent, setResponsecontent] = useState('');
    const [error, setError] = useState('');
    const history = useHistory();

    useEffect(() => {
        axios.get(
            `/api/responses/${topicid}`)
            .then(res => {
                const responseData = res.data;
                for (var i=0; i<responseData.length; i++) {
                    responseData[i]['formatted_date'] = new Date(responseData[i].added_date);
                }
                const sortedResponses = responseData.sort((a, b) => b.formatted_date - a.formatted_date);
                setResponsecontent(sortedResponses);
                setLoading(false)
            })
            .catch(error => {
                window.alert(error.response.data.msg);
            })
    }, [history, props])

    const handleDeleteResponse = (responseID) => {
        axios.delete(`/api/response/${responseID}/delete`, addTokenHeader())
            .then(res => {
                window.location.reload();
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                }
                window.alert(error.response.data.msg);
            })
    }
    
    const onDeleteResponse = (responseID) => {
        if (window.confirm('你确定是否想删除?')) handleDeleteResponse(responseID);
    }

    return (
        <div style={{marginBottom: 80}}>
            {
                responsecontent &&
                responsecontent.map(response => {
                    return (
                        <div key={response._id}>
                            <p style={{marginTop: 10, fontSize: 22}} className="mb-2 text-muted"><Image src={faceIcon} style={{width: 20, marginRight: 10}} />{response.username}</p>
                            <p className="mb-2 text-muted">{convertDatesToChinese(response.added_date)}</p>
                            <div 
                                dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(response.responsecontent)}} 
                                className="html-outer"
                                style={{marginBottom: 20, marginTop: 20}}
                            />
                            <Image src={deleteIcon} style={{width: 20, cursor: 'pointer'}} onClick={() => onDeleteResponse(response._id)} />
                            <hr />
                        </div>
                    )
                })
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(GetResponses);