import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Footer = () => {
    return (
        <div id="footer">
            <div className="container">
                <Row>
                    <Col lg={4}>
                    <a href='https://www.jszhidao.com'>
                        <p className="text-center">江苏智道有限工程公司</p>
                    </a>
                    </Col>
                    <Col lg={4}>
                        <p className="text-center footer" style={{marginBottom: 0}}>南京市新模范马路5号科技</p>
                        <p className="text-center footer">创新大楼21楼B座</p>
                    </Col>
                    <Col lg={4}>
                        <p className="text-center footer">+86 25 85582257 85582240</p>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default Footer;