export const addTokenHeader = () => {
    const token = localStorage.getItem('token');
    const config = {
        headers: {
            'Content-type': 'application/json'
        }
    };
  
    if (token) {
        config.headers['x-auth-token'] = token;
    }
  
    return config;
};

export const logout = () => {
    localStorage.removeItem('username');
    localStorage.removeItem('token');
}

export const getDates = (mongoDate) => {
    const currentTime = new Date(mongoDate);
    const year = currentTime.getFullYear();
    const month = currentTime.getMonth() + 1;
    const day = currentTime.getDate();
    return { year, month, day }
}

export const convertDatesToChinese = (timestamp) => {
    const {year, month, day} = getDates(timestamp);
    return `${year}年${month}月${day}日`;
}