import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from '../pages/Login';
import Home from '../pages/Home';
import NotFound from '../pages/NotFound';
import Signup from '../pages/Signup';
import AddTopic from '../pages/AddTopic';
import SingleTopic from '../pages/SingleTopic';
import UserList from '../pages/UserList';
import NewUser from '../pages/NewUser';
import EditUser from '../pages/EditUser';

export default () => (
	<div>
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/topic/:id" exact component={SingleTopic}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/signup" exact component={Signup}/>
                <Route path="/addtopic" exact component={AddTopic}/>
                <Route path="/users" exact component={UserList}/>
                <Route path="/newuser" exact component={NewUser}/>
                <Route path="/user/:id/edit" exact component={EditUser} />
                <Route path="*" component={NotFound}/>
            </Switch>
        </BrowserRouter>
  	</div>
)