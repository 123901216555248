import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import DOMPurify from 'dompurify';
import { Badge, Row, Col, Image, Button } from 'react-bootstrap';
import SiteNav from '../components/SiteNav';
import AddResponse from '../components/AddResponse';
import Footer from '../components/Footer';
import { addTokenHeader, convertDatesToChinese, logout } from '../utils';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import GetResponses from '../components/GetResponses';
import deleteIcon from '../images/delete-icon.png';
import faceIcon from '../images/face_icon.jpeg';
import NotFound from './NotFound';

const SingleTopic = (props) => {
    const [showWindow, setShowWindow] = useState(false);
    const [topic, setTopic] = useState();
    const [loading, setLoading] = useState(true);
    const [redirect, setRedirect] = useState(false);
    let history = useHistory()
    const topicid = props.match.params.id;

    useEffect(() => {
        axios.get(
            `/api/topic/${topicid}`, addTokenHeader())
            .then(res => {
                setTopic(res.data)
                setLoading(false)
            })
            .catch(error => {
                setRedirect(true);
            })
    }, [history, props])

    const handleDeleteTopic = () => {
        axios.delete(`/api/topic/${topicid}/delete`, addTokenHeader())
            .then(res => {
                history.push('/');
            })
            .catch(error => {
                if (error.response.status === 401) {
                    logout();
                    props.toggleLogin(false);
                    props.toggleRole(null);
                }
                window.alert(error.response.data.msg);
            })
    }
    
    const onDeleteTopic = () => {
        if (window.confirm('你确定是否想删除本话题?')) handleDeleteTopic();
    }

    const onDisplayResponseWindow = () => {
        setShowWindow(true);
    }

    const closeDisplayResponseWindow = () => {
        setShowWindow(false);
    }

    return (
        
        <div>
            {
                redirect ?
                <NotFound /> :
                <SiteNav />
            }
            {
                topic && !redirect &&
                <div>
                    <div className="container" style={{padding: 40}}>
                        <Row>
                            <Col lg={2} />
                            <Col lg={8} >
                                <h1>{topic.topicname}</h1>
                                <div 
                                    dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(topic.topiccontent)}} 
                                    className="html-outer"
                                    style={{marginBottom: 20, marginTop: 20}}
                                />
                                {
                                    topic.keywords.map((keyword, index) => {
                                        return (
                                            <span key={index}>
                                                <Badge variant="primary" style={{padding: 10, marginBottom: 5}}>
                                                    {keyword}
                                                </Badge>{' '}
                                            </span>
                                        )
                                    })
                                }
                                <p style={{marginTop: 10}} className="mb-2 text-muted"><Image src={faceIcon} style={{width: 20, marginRight: 10}} />{topic.username}</p>
                                {console.log(topic)}
                                <p style={{marginTop: 10}} className="mb-2 text-muted">{convertDatesToChinese(topic.added_date)}</p>
                                <Image src={deleteIcon} style={{width: 20, cursor: 'pointer', marginTop: 25}} onClick={onDeleteTopic} />
                            </Col>
                            <Col lg={2} />
                        </Row>
                    </div>
                    <hr />
                    <div className="container">
                        <h5 className="text-center" style={{marginTop: 40, marginBottom: 40}}>评论</h5>
                        <Row>
                            <Col lg={2} />
                            <Col lg={8}>
                                {
                                    !showWindow ?
                                    <Button style={{display: 'block', margin: 'auto'}} variant="info" onClick={onDisplayResponseWindow}>回答本问题</Button> :
                                    <AddResponse topicid={props.match.params.id} closeDisplayResponseWindow={closeDisplayResponseWindow} />
                                }
                                <GetResponses topicid={props.match.params.id} />
                            </Col>
                            <Col lg={2} />
                        </Row>
                    </div>
                </div>
            }
            <Footer />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(SingleTopic);