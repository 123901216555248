import React from 'react';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import { logout } from '../utils';
import logo from '../images/logo-orig2.png';

const SiteNav = (props) => {
    const history = useHistory()
    
    const handleOnLogout = () => {
        logout();
        props.toggleLogin(false);
        props.toggleRole(null);
        history.push("/login");
    }

    const { isLoggedIn, role } = props;
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand>
                <Link to="/">
                    <Image src={logo} alt="logo"/>
                </Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto" />
                <Nav>
                    {
                        isLoggedIn && role === 'SUPER' &&
                        <NavDropdown title="用户管理" id="user-nav-dropdown">
                            <NavDropdown.Item href="/users">用户列表</NavDropdown.Item>
                            <NavDropdown.Item href="/newuser">创造新用户</NavDropdown.Item>
                        </NavDropdown>
                    }
                    {
                        !isLoggedIn ?
                        <>
                            <Nav.Link 
                                href={"/login"}
                            >
                                登录
                            </Nav.Link>
                            <Nav.Link 
                                href={"/signup"}
                            >
                                注册
                            </Nav.Link>
                        </> :
                        <>
                            <Nav.Link 
                                href={"/"}
                            >
                                话题列表
                            </Nav.Link>
                            <Nav.Link 
                                href={"/addtopic"}
                            >
                                增加讨论话题
                            </Nav.Link>
                            <Nav.Link 
                                href={""}
                                onClick={handleOnLogout}
                            >
                                退出
                            </Nav.Link>
                        </>
                    }
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(SiteNav);