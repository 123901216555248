import axios from 'axios';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Form, Button, Alert, Row, Col, Badge } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import SiteNav from '../components/SiteNav';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import DatePicker from 'react-datepicker';
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import zh from 'date-fns/locale/zh-CN';
import { addTokenHeader, logout } from '../utils';
import "react-datepicker/dist/react-datepicker.css";
import Footer from '../components/Footer';
registerLocale('zh-CN', zh)

const AddTopic = (props) => {
    const [addedDate, setAddedDate] = useState(new Date());
    const [topicname, setTopicname] = useState('');
    const [topiccontent, setTopiccontent] = useState('');
    const [currentKeyword, setCurrentKeyword] = useState('');
    const [keywords, setKeywords] = useState([]);
    const [error, setError] = useState('');
    const history = useHistory();

    const handleOnTopicnameUpdate = (e) => {
        e.preventDefault();
        setTopicname(e.target.value);
    }

    const handleOnTopiccontentUpdate = (e) => {
        setTopiccontent(e);
    }

    const handleOnCurrentKeywordUpdate = (e) => {
        e.preventDefault();
        setCurrentKeyword(e.target.value);
    }

    const handleOnKeywordsUpdate = () => {
        if (currentKeyword !== '' && keywords.length < 5) {
            setKeywords(oldArray => [...oldArray, currentKeyword]);
        }
    }

    const handleOnKeywordsClear = () => {
        setKeywords([]);
    }

    const handleOnSubmit = (e) => {
        const payload = {
            topicname,
            topiccontent,
            keywords,
            addedDate
        };

        axios.post('/api/topic/submit', payload, addTokenHeader())
        .then(res => {
            setError('')
            history.push("/");
        }).catch(error => {
            if (error.response.status === 401) {
                logout();
                props.toggleLogin(false);
                props.toggleRole(null);
            }
            setError(error.response.data.msg);
        });
    };

    return (
        <div>
            <SiteNav />
            <div style={{padding: 40}}>
                <h1 className="text-center">
                    增加新话题
                </h1>
                <div className="container" style={{marginTop: 30, maxWidth: 800}}>
                    <Card>
                        <Card.Body>
                            <Form>
                                {
                                    error &&
                                    <Alert variant="danger">
                                        {error}
                                    </Alert>
                                }
                                <Form.Group controlId="formBasicTopicname">
                                    <Form.Label>话题名</Form.Label>
                                    <Form.Control type="text" placeholder="输入话题名" onChange={handleOnTopicnameUpdate} />
                                </Form.Group>
                                <ReactQuill 
                                    style={{marginBottom: 20}}
                                    value={topiccontent}
                                    modules={{toolbar: [
                                        [{ header: [1, 2, 3, false] }],
                                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                        [{ list: 'ordered' }, { list: 'bullet' }],
                                        ['link', 'image'],
                                        ['clean'],
                                      ]}}
                                    onChange={handleOnTopiccontentUpdate} 
                                />
                                <Row>
                                    <Col>
                                        <Form.Group controlId="formBasicKeywords">
                                            <Form.Label>关键词</Form.Label>
                                            <Form.Control type="text" placeholder="输入一到五个关键词" onChange={handleOnCurrentKeywordUpdate} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Button style={{marginTop: 32}} variant="primary" onClick={handleOnKeywordsUpdate}>+</Button>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        {
                                            keywords &&
                                            keywords.map((keyword, ind) => {
                                                return (
                                                    <span key={ind}>
                                                        <Badge variant="primary" style={{padding: 10, marginBottom: 5}}>
                                                            {keyword}
                                                        </Badge>{' '}
                                                    </span>
                                                )
                                            })
                                        }
                                    </Col>
                                    <Col>
                                        {
                                            keywords.length > 0 &&
                                            <Button variant="danger" onClick={handleOnKeywordsClear}>清空</Button>
                                        }
                                    </Col>
                                </Row>
                                {/* <Form.Label>话题增加时间 </Form.Label><br />
                                <DatePicker selected={addedDate} locale='zh-CN' onChange={(date) => setAddedDate(date)} /> */}
                                <div style={{textAlign: "center", marginTop: 15}}>
                                    <Button variant="primary" onClick={handleOnSubmit}>
                                        创造新话题
                                    </Button>
                                </div>
                            </Form>
                        </Card.Body>
                    </Card>
                </div>
            </div>
            <Footer />
        </div>
    )
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(AddTopic);