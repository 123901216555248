import axios from 'axios';
import React from 'react';
import { Form, Button, Alert, Card } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {toggleLogin, toggleRole} from '../actions/index';
import SiteNav from '../components/SiteNav';
import Footer from '../components/Footer';

class Login extends React.Component {
    state = {
        username: '',
        password: '',
        error: ''
    }

    handleOnUsernameUpdate = (e) => {
        e.preventDefault();
        this.setState({
            username: e.target.value
        })
    }

    handleOnPasswordUpdate = (e) => {
        e.preventDefault();
        this.setState({
            password: e.target.value
        })
    }

    handleOnSubmit = (e) => {
        const payload = {
            username: this.state.username,
            password: this.state.password
        };

        axios.post('/api/login/submit', payload)
        .then(res => {
            localStorage.setItem('username', res.data.user.username);
            localStorage.setItem('token', res.data.token);
            this.setState({
                error: '',
            })
            this.props.toggleLogin(true);
            this.props.toggleRole(res.data.user.role);
        }).catch(error => {
            console.log(error)
            this.setState({
                error: error.response.data.msg
            })
            this.props.toggleLogin(false);
            this.props.toggleRole(null);
        });
    };

    render () {
        return (
            <div>
                <SiteNav />
                <div style={{padding: 40}}>
                    <h1 className="text-center">
                        登录
                    </h1>
                    <div className="container" style={{marginTop: 30, maxWidth: 600}}>
                        {
                            this.props.isLoggedIn && this.props.role &&
                                <Redirect to="/"/>  
                            
                        }
                        <Card>
                            <Card.Body>
                                <Form>
                                    {
                                        this.state.error &&
                                        <Alert variant="danger">
                                            {this.state.error}
                                        </Alert>
                                    }
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label>用户名</Form.Label>
                                        <Form.Control type="text" placeholder="输入用户名" onChange={this.handleOnUsernameUpdate} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicPassword">
                                        <Form.Label>密码</Form.Label>
                                        <Form.Control type="password" placeholder="输入密码" onChange={this.handleOnPasswordUpdate} />
                                    </Form.Group>
                                    <div style={{textAlign: "center"}}>
                                        <Button variant="primary" onClick={this.handleOnSubmit}>
                                            登录
                                        </Button>
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        isLoggedIn: state.isLoggedIn,
        role: state.role
    };
}
  
function matchDispatchToProps(dispatch){
    return bindActionCreators({toggleLogin: toggleLogin, toggleRole: toggleRole}, dispatch);
}
  
export default connect(mapStateToProps, matchDispatchToProps)(Login);